import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { match } from 'react-router-dom';
import { Button } from '../../../../global/components/button';
import { InfoItem } from '../../../../global/components/info-item';
import { goToState } from '../../../../global/helpers/url-parse.helper';
import { Header, Root } from './group-card.styles';
import { useSpinner } from '../../../../global/hooks/use-spinner';
import { InfoLine } from '../../../../global/components/info-line';
import { clearGroupCard, deleteGroupCard, loadGroupCard } from '../../redux/groups.actions';
import { getGroupCard } from '../../redux/groups.selectors';
import MapComponent, { MapObject } from '../../../../global/components/map/map';
import { getNumberCoordByString } from '../../../../global/helpers/map.helper';
import { AuthService } from '../../../../auth/services/AuthService';
import { MemberItem } from '../../../family/pages/card/family-card.styles';
import { BottomPadding } from '../../../../global/components/main-wrap/main-wrap.styles';
import { TableContainer } from '@mui/material';

type GroupCardPageRouteParams = {
    id: string;
};

type GroupCardProp = {
    match: match<GroupCardPageRouteParams>;
};

const GroupCard = (props: GroupCardProp) => {
    const showForLeader = useMemo(() => AuthService.availableForLeader(), [AuthService.availableForLeader]);
    const showForAdmin = useMemo(() => AuthService.availableForAdmin(), [AuthService.availableForAdmin]);
    const dispatch = useDispatch();
    const spinnerRunner = useSpinner(dispatch);
    const groupCard = useSelector(getGroupCard);
    const [id, setId] = useState<number | null>(null);

    useEffect(() => {
        if (!showForLeader) {
            goToState('/');
            return;
        }
    }, []);

    useEffect(() => {
        if (props.match.params.id && Number(props.match.params.id) !== id) {
            dispatch(clearGroupCard());
            setId(Number(props.match.params.id));
            spinnerRunner(loadGroupCard(Number(props.match.params.id)));
        }
    }, [props.match.params.id]);

    const editAvailableAsLeader = useMemo(() => {
        const member = AuthService.getUserInfo()?.member;
        if (!member || !groupCard || !groupCard.lead) {
            return false;
        }
        return member.id === groupCard.lead.id || (groupCard.assist && member.id === groupCard.assist.id);
    }, [groupCard]);

    const goToList = useCallback(() => {
        goToState('/groups');
    }, []);

    const goToEdit = useCallback(() => {
        goToState(`/group/${props.match.params.id}/edit`);
    }, [props.match.params.id]);

    const goToMap = useCallback(() => {
        goToState('/groups/map');
    }, []);

    const goToDelete = useCallback(() => {
        if (!id) {
            return;
        }
        confirmAlert({
            title: 'Удаление',
            message: 'Вы уверены, что хотите удалить эту запись?',
            buttons: [
                {
                    label: 'Да',
                    onClick: async () => {
                        await spinnerRunner(deleteGroupCard(id));
                        goToList();
                    },
                },
                {
                    label: 'Нет',
                    onClick: () => null,
                },
            ],
        });
    }, [confirmAlert, id, deleteGroupCard, spinnerRunner, goToList]);

    const coordsValue = useMemo(() => {
        return getNumberCoordByString(groupCard?.coords);
    }, [groupCard, getNumberCoordByString]);

    const mapObjects = useMemo<MapObject[]>(() => {
        return [{
            coords: coordsValue,
        }];
    }, [coordsValue]);

    if (!groupCard) {
        return (
            <Root>
                <Header>
                    {!!showForAdmin && <>
                        <Button
                            title="Перейти в список"
                            onClick={goToList}
                        />
                    </>}
                </Header>
            </Root>
        );
    }

    return (
        <Root>
            <Header>
                {!!showForAdmin &&
                    <Button
                        title="Перейти в список"
                        onClick={goToList}
                    />
                }
                {
                    (!!showForAdmin || !!editAvailableAsLeader) &&
                    <Button
                        title="Редактировать"
                        onClick={goToEdit}
                    />
                }
                {!!showForAdmin &&
                    <Button
                        title="Карта всех групп"
                        onClick={goToMap}
                    />
                }
                {!!showForAdmin &&
                    <Button
                        title="Удалить"
                        onClick={goToDelete}
                    />
                }
            </Header>
            <TableContainer sx={{ maxHeight: 'calc(100vh - 75px);' }}>
                <InfoLine>
                    <InfoItem
                        title="Название"
                        value={groupCard.name}
                    />
                    <InfoItem
                        title="Район"
                        value={groupCard.district}
                    />
                    {!!showForAdmin && <InfoItem
                        title="Чат в телеграмме"
                        value={<div>
                            {
                                groupCard.chat && <a href={`#/chat-info/${groupCard.chat.id}`}>{groupCard.chat.chatName}</a>
                            }
                        </div>}
                    />}
                </InfoLine>
                <InfoLine>
                    <InfoItem
                        title="День недели"
                        value={groupCard.day?.label || 'не известно'}
                    />
                    <InfoItem
                        title="Время начала"
                        value={groupCard.startTime || 'не известно'}
                    />
                </InfoLine>
                <InfoLine>
                    <InfoItem
                        title="Лидер"
                        value={<div>
                            {
                                !!groupCard.lead &&
                                <a href={`#/member/${groupCard.lead.id}`}>{groupCard.lead.name}</a>
                            }
                        </div>}
                    />
                    <InfoItem
                        title="Второй лидер"
                        value={<div>
                            {
                                !!groupCard.assist &&
                                <a href={`#/member/${groupCard.assist.id}`}>{groupCard.assist.name}</a>
                            }
                        </div>}
                    />
                </InfoLine>
                <InfoItem
                    title="Участники"
                    value={<div>
                        {
                            groupCard.chat?.profiles?.map(el =>
                                <MemberItem key={el.id}>
                                    <a href={`#/member/${el.id}`}>{el.name}</a>
                                </MemberItem>
                            )
                        }
                    </div>}
                />
                <InfoItem
                    title="Координаты"
                    value={<MapComponent
                        objects={mapObjects}
                        disable
                    />}
                />
                <BottomPadding />
            </TableContainer>
        </Root>
    );
};

export default GroupCard;

import React, { useCallback, useEffect, useMemo } from 'react';
import { ClipLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { confirmAlert } from 'react-confirm-alert';
import {
    goToState,
} from '../../../../global/helpers/url-parse.helper';
import { Root, Header, RowWrap, ChildIcon, EmptyChildIcon } from './ministries-list.styles';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { MinistryList as MinistryListModel } from '../../models/ministry-list.model';
import {
    getLocationSearchParamByKey,
    makeGetIsShowLocalSpinner,
} from '../../../../global/redux/global/global.selectors';
import { useLocalSpinner } from '../../../../global/hooks/use-local-spinner';
import { LocalSpinnerKeysEnum } from '../../../../global/constants';
import { BottomPadding, LocalSpinner } from '../../../../global/components/main-wrap/main-wrap.styles';
import { Button } from '../../../../global/components/button';
import { getMinistriesList } from '../../redux/ministries.selectors';
import { closeMinistryChild, deleteMinistryCard, updateChildMinistries } from '../../redux/ministries.actions';
import { useDebounce } from '../../../../global/hooks/use-debounce';
import { AuthService } from '../../../../auth/services/AuthService';
import { LinkA } from '../../../../global/theme/global.styles';
import classNames from 'classnames';

type MinistryItemProps = {
    level: number,
    item: MinistryListModel;
    searchString: string | null;
};

const MinistryItem = (props: MinistryItemProps) => {
    const dispatch = useDispatch();
    const localSpinnerRunner = useLocalSpinner(dispatch);
    const goToCard = useCallback(() => {
        goToState(`/ministry/${props.item.id}`);
    }, [props.item.id]);
    const goToEdit = useCallback(() => {
        goToState(`/ministry/${props.item.id}/edit`);
    }, [props.item.id]);
    const goToDelete = useCallback(() => {
        confirmAlert({
            title: 'Удаление',
            message: 'Вы уверены, что хотите удалить эту запись?',
            buttons: [
                {
                    label: 'Да',
                    onClick: async () => {
                        await localSpinnerRunner(deleteMinistryCard(props.item.id), LocalSpinnerKeysEnum.ministriesList);
                        localSpinnerRunner(updateChildMinistries(null), LocalSpinnerKeysEnum.ministriesList);
                    },
                },
                {
                    label: 'Нет',
                    onClick: () => null,
                },
            ],
        });
    }, [confirmAlert, props.item.id, props.searchString]);

    const childIconClassNames = useMemo(() => classNames('action-icon', {
        isOpen: !!props.item.child.length,
    }), [props.item.child.length]);

    const toggleChild = useCallback(() => {
        if (props.item.child.length) {
            localSpinnerRunner(closeMinistryChild(props.item.id), LocalSpinnerKeysEnum.ministriesList);
        } else {
            localSpinnerRunner(updateChildMinistries(`${props.item.id}`), LocalSpinnerKeysEnum.ministriesList);
        }
    }, [props.item.child.length, props.item.id]);

    const nameStyle = useMemo(() => ({
        marginLeft: `${props.level * 40}px`,
    }), [props.level]);

    return (
        <>
            <TableRow hover role="checkbox" tabIndex={-1} onDoubleClick={goToCard}>
                <TableCell align="left">
                    <RowWrap>
                        {
                            props.item.hasChild &&
                            <ChildIcon onClick={toggleChild} className={childIconClassNames} />
                        }
                        {
                            !props.item.hasChild &&
                            <EmptyChildIcon />
                        }
                    </RowWrap>
                </TableCell>
                <TableCell align="left">
                    <LinkA href={`#/ministry/${props.item.id}`} style={nameStyle}>
                        {props.item.name}
                    </LinkA>
                </TableCell>
                <TableCell align="left">{props.item.description}</TableCell>
                <TableCell align="left">{props.item.lead.lastName} {props.item.lead.firstName}</TableCell>
                <TableCell align="left">{props.item.assist?.lastName} {props.item.assist?.firstName}</TableCell>
                <TableCell align="left">
                    <RowWrap>
                        <EditIcon onClick={goToEdit} className="action-icon" />
                        <DeleteIcon onClick={goToDelete} className="action-icon" />
                    </RowWrap>
                </TableCell>
            </TableRow>
            {
                props.item.child.map(child =>
                    <MinistryItem
                        level={props.level + 1}
                        item={child}
                        key={child.id}
                        searchString={props.searchString}
                    />
                )
            }
        </>
    );
};

const MinistriesList = () => {
    const showForAdmin = useMemo(() => AuthService.availableForAdmin(), [AuthService.availableForAdmin]);
    const dispatch = useDispatch();
    const ministriesList = useSelector(getMinistriesList);
    const localSpinnerRunner = useLocalSpinner(dispatch);
    const isShowSpinner = useSelector(
        makeGetIsShowLocalSpinner(LocalSpinnerKeysEnum.ministriesList),
    );
    const searchString = useSelector(
        getLocationSearchParamByKey('searchString'),
    );
    const debounceSearchInputText = useDebounce(searchString);

    useEffect(() => {
        if (!showForAdmin) {
            goToState('/');
            return;
        }
    }, []);
    
    useEffect(() => {
        localSpinnerRunner(
            updateChildMinistries(null),
            LocalSpinnerKeysEnum.ministriesList,
        );
    }, [debounceSearchInputText]);

    const goToCreate = useCallback(() => {
        goToState('/ministry/create');
    }, []);


    // const updateSearchString = useCallback(
    //     (value: string | null) => {
    //         addParamsToUrl([
    //             {
    //                 key: 'searchString',
    //                 value,
    //             },
    //         ]);
    //     },
    //     [addParamsToUrl],
    // );

    // const clearFilters = useCallback(() => {
    //     addParamsToUrl([
    //         {
    //             key: 'searchString',
    //             value: undefined,
    //         },
    //     ]);
    // }, [addParamsToUrl]);

    return (
        <Root>
            <Header>
                <Button title="Создать" onClick={goToCreate} />
            </Header>
            {/* <FilterPanel>
                <InfoItem
                    className="filter-item"
                    title="Поиск"
                    value={
                        <TextInput
                            value={searchString}
                            onChange={updateSearchString}
                        />
                    }
                />
                <Button
                    className="clear-filter-button"
                    title="Сбросить фильтры"
                    onClick={clearFilters}
                />
            </FilterPanel> */}
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 'calc(100vh - 101px);' }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Id</TableCell>
                                <TableCell align="left">Название</TableCell>
                                <TableCell align="left">Описание</TableCell>
                                <TableCell align="left">Лидер</TableCell>
                                <TableCell align="left">Помощник</TableCell>
                                <TableCell align="left" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ministriesList.map((ministry, index) => (
                                <MinistryItem
                                    level={0}
                                    item={ministry}
                                    key={index}
                                    searchString={debounceSearchInputText}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <ClipLoader loading={isShowSpinner} size={30} css={LocalSpinner} />
            <BottomPadding />
        </Root>
    );
};

export default MinistriesList;

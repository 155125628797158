import React, { useCallback, useEffect, useMemo } from 'react';
import { ClipLoader } from 'react-spinners';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { confirmAlert } from 'react-confirm-alert';
import {
    addParamsToUrl,
    goToState,
} from '../../../../global/helpers/url-parse.helper';
import { Root, Header, FilterPanel } from './groups-list.styles';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { GroupList as GroupListModel } from '../../models/group-list.model';
import {
    getLocationSearchParamByKey,
    makeGetIsShowLocalSpinner,
} from '../../../../global/redux/global/global.selectors';
import { useLocalSpinner } from '../../../../global/hooks/use-local-spinner';
import { LocalSpinnerKeysEnum } from '../../../../global/constants';
import { BottomPadding, LocalSpinner } from '../../../../global/components/main-wrap/main-wrap.styles';
import { Button } from '../../../../global/components/button';
import { getGroupsTotalCount, getGroupsList } from '../../redux/groups.selectors';
import { deleteGroupCard, updateGroups } from '../../redux/groups.actions';
import { GetGroupListQueryParams } from '../../types/get-group-list-query-params';
import { GetGroupListBodyParams } from '../../types/get-group-list-body-params';
import { useDebounce } from '../../../../global/hooks/use-debounce';
import { InfoItem } from '../../../../global/components/info-item';
import { TextInput } from '../../../../global/components/form-controls/text-input/text-input';
import { AuthService } from '../../../../auth/services/AuthService';
import { LinkA } from '../../../../global/theme/global.styles';

type GroupItemProps = {
    item: GroupListModel;
    queryParams: GetGroupListQueryParams;
    bodyParams: GetGroupListBodyParams
};

const GroupItem = (props: GroupItemProps) => {
    const dispatch = useDispatch();
    const localSpinnerRunner = useLocalSpinner(dispatch);
    const goToCard = useCallback(() => {
        goToState(`/group/${props.item.id}`);
    }, [props.item.id]);
    const goToEdit = useCallback(() => {
        goToState(`/group/${props.item.id}/edit`);
    }, [props.item.id]);
    const goToDelete = useCallback(() => {
        confirmAlert({
            title: 'Удаление',
            message: 'Вы уверены, что хотите удалить эту запись?',
            buttons: [
                {
                    label: 'Да',
                    onClick: async () => {
                        await localSpinnerRunner(deleteGroupCard(props.item.id), LocalSpinnerKeysEnum.groupsList);
                        localSpinnerRunner(updateGroups(props.queryParams, props.bodyParams), LocalSpinnerKeysEnum.groupsList);
                    },
                },
                {
                    label: 'Нет',
                    onClick: () => null,
                },
            ],
        });
    }, [confirmAlert, props.item.id, props.queryParams, props.bodyParams]);
    return (
        <TableRow hover role="checkbox" tabIndex={-1} onDoubleClick={goToCard}>
            <TableCell align="left">{props.item.id}</TableCell>
            <TableCell align="left">
                <LinkA href={`#/group/${props.item.id}`}>
                    {props.item.name}
                </LinkA>
            </TableCell>
            <TableCell align="left">{props.item.district}</TableCell>
            <TableCell align="left">{props.item.lead?.lastName || ''} {props.item.lead?.firstName || ''}</TableCell>
            <TableCell align="left">
                <EditIcon onClick={goToEdit} className="action-icon" />
                <DeleteIcon onClick={goToDelete} className="action-icon" />
            </TableCell>
        </TableRow>
    );
};

const GroupsList = () => {
    const showForAdmin = useMemo(() => AuthService.availableForAdmin(), [AuthService.availableForAdmin]);
    const dispatch = useDispatch();
    const pageNo = useSelector(getLocationSearchParamByKey('pageNo'));
    const pageSize = useSelector(getLocationSearchParamByKey('pageSize'));
    const groupsList = useSelector(getGroupsList);
    const groupsTotalCount = useSelector(getGroupsTotalCount);
    const localSpinnerRunner = useLocalSpinner(dispatch);
    const isShowSpinner = useSelector(
        makeGetIsShowLocalSpinner(LocalSpinnerKeysEnum.groupsList),
    );
    const searchString = useSelector(
        getLocationSearchParamByKey('searchString'),
    );
    const debounceSearchInputText = useDebounce(searchString);

    const queryParams: GetGroupListQueryParams = useMemo(() => ({
        pageNo: pageNo ? Number(pageNo) : 0,
        pageSize: pageSize ? Number(pageSize) : 10,
    }), [pageNo, pageSize]);

    const bodyParams: GetGroupListBodyParams = useMemo(() => ({
        searchString: debounceSearchInputText || '',
    }), [debounceSearchInputText]);

    useEffect(() => {
        if (!showForAdmin) {
            goToState('/');
            return;
        }
    }, []);
    
    useEffect(() => {
        localSpinnerRunner(
            updateGroups(
                queryParams,
                bodyParams,
            ),
            LocalSpinnerKeysEnum.groupsList,
        );
    }, [queryParams, bodyParams]);

    const pageNoValue = useMemo(
        () => (groupsTotalCount ? (pageNo === null ? 0 : Number(pageNo)) : 0),
        [pageNo, groupsTotalCount],
    );
    const pageSizeValue = useMemo(
        () =>
            groupsTotalCount ? (pageSize === null ? 10 : Number(pageSize)) : 0,
        [pageSize, groupsTotalCount],
    );
    const handleChangePage = useCallback(
        (
            event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
            page: number,
        ) => {
            addParamsToUrl([
                {
                    key: 'pageNo',
                    value: page,
                },
            ]);
        },
        [addParamsToUrl],
    );
    const handleChangeRowsPerPage = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            addParamsToUrl([
                {
                    key: 'pageNo',
                    value: 0,
                },
                {
                    key: 'pageSize',
                    value: event.target.value,
                },
            ]);
        },
        [addParamsToUrl],
    );
    const goToCreate = useCallback(() => {
        goToState('/group/create');
    }, []);

    const goToMap = useCallback(() => {
        goToState('/groups/map');
    }, []);

    const updateSearchString = useCallback(
        (value: string | null) => {
            addParamsToUrl([
                {
                    key: 'searchString',
                    value,
                },
            ]);
        },
        [addParamsToUrl],
    );

    const clearFilters = useCallback(() => {
        addParamsToUrl([
            {
                key: 'searchString',
                value: undefined,
            },
            {
                key: 'pageNo',
                value: 0,
            },
            {
                key: 'pageSize',
                value: 10,
            },
        ]);
    }, [addParamsToUrl]);

    return (
        <Root>
            <Header>
                <Button title="Создать" onClick={goToCreate} />
                <Button title="Карта" onClick={goToMap} />
            </Header>
            <FilterPanel>
                <InfoItem
                    className="filter-item"
                    title="Поиск"
                    value={
                        <TextInput
                            value={searchString}
                            onChange={updateSearchString}
                        />
                    }
                />
                <Button
                    className="clear-filter-button"
                    title="Сбросить фильтры"
                    onClick={clearFilters}
                />
            </FilterPanel>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 'calc(100vh - 244px);' }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Id</TableCell>
                                <TableCell align="left">Название</TableCell>
                                <TableCell align="left">Район</TableCell>
                                <TableCell align="left">Лидер</TableCell>
                                <TableCell align="left" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {groupsList.map((group, index) => (
                                <GroupItem
                                    item={group}
                                    key={index}
                                    queryParams={queryParams}
                                    bodyParams={bodyParams}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[3, 10, 25, 100]}
                    component="div"
                    count={groupsTotalCount}
                    rowsPerPage={pageSizeValue}
                    page={pageNoValue}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage="Записей на странице"
                />
            </Paper>
            <ClipLoader loading={isShowSpinner} size={30} css={LocalSpinner} />
            <BottomPadding />
        </Root>
    );
};

export default GroupsList;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AdoptedInfoCardEditProps } from '../../../../global/types/adopted-info-card-edit-props';
import { useDispatch, useSelector } from 'react-redux';
import { getAdoptedInfoCard } from '../../redux/members.selectors';
import { InfoLine } from '../../../../global/components/info-line';
import { InfoItem } from '../../../../global/components/info-item';
import { TextInput } from '../../../../global/components/form-controls/text-input/text-input';
import MultipleSelectEntity, {
    EntityForSelect,
} from '../../../../global/components/multiple-select-entity/multiple-select-entity';
import SingleSelectEntity from '../../../../global/components/single-select-entity/single-select-entity';
import Select from 'react-select';
import { Calendar } from 'primereact/calendar';
import {
    AbsenteeLetterStatusItem,
    absenteeLetterStatusOptions,
} from '../../models/absentee-letter-status.model';
import {
    TestimonyStatusItem,
    testimonyStatusOptions,
} from '../../models/testimony-status.model';
import {
    TestimonyRevisionItem,
    testimonyRevisionOptions,
} from '../../models/testimony-revision.model';
import {
    InterviewResultItem,
    interviewResultOptions,
} from '../../models/interview-result.model';
import { loadMembersForSelect } from '../../helpers/api.helpers';
import { CreateAdoptedInfoParams } from '../../types/create-adopted-info-params';
import { parseDateForApi } from '../../../../global/helpers/date.helper';
import { useEventValue } from '../../../../global/hooks/use-event-value';
import {
    parseMemberItemToEntityForSelect,
    parseMembersListToEntityForSelect,
} from '../../helpers/api.helpers';
import { useSpinner } from '../../../../global/hooks/use-spinner';
import { updateMemberCardAdoptedInfo } from '../../redux/members.actions';
import { useCheckNotSaveData } from '../../../../global/hooks/use-check-not-save-data';
import { TabIds } from '../../../../global/hooks/use-member-tabs';
import { TableContainer } from '@mui/material';

function AdoptedInfoCardEdit({ ov = false, id, isSaveCallback, setChangeInfo, saveTrigger, changedMembership }: AdoptedInfoCardEditProps) {
    const dispatch = useDispatch();
    const adoptedInfoCard = useSelector(getAdoptedInfoCard);
    const [testimonyLink, setTestimonyLink] = useState<string | null>(null);
    const [absenteeLetterStatus, setAbsenteeLetterStatus] =
        useState<AbsenteeLetterStatusItem | null>(null);
    const [testimonyStatus, setTestimonyStatus] =
        useState<TestimonyStatusItem | null>(null);
    const [testimonyRevision, setTestimonyRevision] =
        useState<TestimonyRevisionItem | null>(null);
    const [interviewDate, setInterviewDate] = useState<Date | null>(null);
    const [interviewTime, setInterviewTime] = useState<string | null>(null);
    const [interviewResult, setInterviewResult] =
        useState<InterviewResultItem | null>(null);
    const [ministryInfo, setMinistryInfo] = useState<string | null>(null);
    const [personalLeader, setPersonalLeader] =
        useState<EntityForSelect | null>(null);
    const [presentForChurch, setPresentForChurch] =
        useState<EntityForSelect | null>(null);
    const [interviewPastors, setInterviewPastors] = useState<EntityForSelect[]>(
        [],
    );
    const params: CreateAdoptedInfoParams = useMemo(() => {
        return {
            absenteeLetterStatus: absenteeLetterStatus?.value || null,
            interviewDate: parseDateForApi(interviewDate),
            interviewPastorsIds: interviewPastors.map((el) => el.id),
            interviewResult: interviewResult?.value || null,
            interviewTime,
            ministryInfo,
            personalLeaderId: personalLeader?.id || null,
            presentForChurchId: presentForChurch?.id || null,
            testimonyLink,
            testimonyRevision: testimonyRevision?.value || null,
            testimonyStatus: testimonyStatus?.value || null,
        };
    }, [
        testimonyStatus,
        testimonyRevision,
        testimonyLink,
        personalLeader,
        presentForChurch,
        ministryInfo,
        interviewTime,
        interviewResult,
        interviewPastors,
        interviewDate,
        absenteeLetterStatus,
    ]);

    const spinnerRunner = useSpinner(dispatch);

    const handleInterviewDateChange = useEventValue(setInterviewDate);

    const { clearCallback } = useCheckNotSaveData({
        data: params, 
        isNotSaveCallback: (isNotSaved) => {
            if (isNotSaved) {
                isSaveCallback(false);
                setChangeInfo(TabIds.Membership, true);
            } else {
                isSaveCallback(true);
                setChangeInfo(TabIds.Membership, false);
            }
        },
    });

    const saveCallback = useCallback(() => {
        if (!id) {
            return;
        }
        spinnerRunner(updateMemberCardAdoptedInfo(id, params));
        clearCallback();
    }, [
        testimonyStatus,
        testimonyRevision,
        testimonyLink,
        personalLeader,
        presentForChurch,
        ministryInfo,
        interviewTime,
        interviewResult,
        interviewPastors,
        interviewDate,
        absenteeLetterStatus,
        clearCallback,
    ]);

    useEffect(() => {
        if (!saveTrigger || !changedMembership) {
            return;
        }
        saveCallback();
    }, [saveTrigger]);
    
    useEffect(() => {
        if (adoptedInfoCard) {
            setTestimonyLink(adoptedInfoCard.testimonyLink);
            setAbsenteeLetterStatus(adoptedInfoCard.absenteeLetterStatus);
            setTestimonyStatus(adoptedInfoCard.testimonyStatus);
            setTestimonyRevision(adoptedInfoCard.testimonyRevision);
            setTestimonyLink(adoptedInfoCard.testimonyLink);
            setInterviewDate(adoptedInfoCard.interviewDate);
            setInterviewTime(adoptedInfoCard.interviewTime);
            setInterviewResult(adoptedInfoCard.interviewResult);
            setMinistryInfo(adoptedInfoCard.ministryInfo);
            setPersonalLeader(
                parseMemberItemToEntityForSelect(
                    adoptedInfoCard.personalLeader,
                ),
            );
            setPresentForChurch(
                parseMemberItemToEntityForSelect(
                    adoptedInfoCard.presentForChurch,
                ),
            );
            setInterviewPastors(
                parseMembersListToEntityForSelect(
                    adoptedInfoCard.interviewPastors,
                ),
            );
        }
    }, [adoptedInfoCard]);

    return (
        <section>
            {ov ? (
                <InfoLine>
                    <InfoItem
                        title="Ссылка на свидетельство"
                        value={
                            <TextInput
                                value={testimonyLink}
                                onChange={setTestimonyLink}
                            />
                        }
                    />
                </InfoLine>
            ) : (
                <TableContainer sx={{ maxHeight: 'calc(100vh - 150px);' }}>
                    <InfoLine>
                        <InfoItem
                            valueWidth={157}
                            title="Статус открепительного письма"
                            value={
                                <Select
                                    options={absenteeLetterStatusOptions}
                                    value={absenteeLetterStatus}
                                    onChange={setAbsenteeLetterStatus}
                                    isClearable
                                />
                            }
                        />
                        <InfoItem
                            valueWidth={157}
                            title="Статус свидетельства"
                            value={
                                <Select
                                    options={testimonyStatusOptions}
                                    value={testimonyStatus}
                                    onChange={setTestimonyStatus}
                                    isClearable
                                />
                            }
                        />
                        <InfoItem
                            valueWidth={157}
                            title="Статус доработки свидетельства"
                            value={
                                <Select
                                    options={testimonyRevisionOptions}
                                    value={testimonyRevision}
                                    onChange={setTestimonyRevision}
                                    isClearable
                                />
                            }
                        />
                        <InfoItem
                            title="Ссылка на свидетельство"
                            value={
                                <TextInput
                                    value={testimonyLink}
                                    onChange={setTestimonyLink}
                                />
                            }
                        />
                    </InfoLine>
                    <InfoLine>
                        <InfoItem
                            title="Дата собеседования"
                            value={
                                <Calendar
                                    locale={'ru'}
                                    className="local-datapicker"
                                    value={interviewDate}
                                    onChange={handleInterviewDateChange}
                                    dateFormat={'dd.mm.yy'}
                                />
                            }
                        />
                        <InfoItem
                            title="Время собеседования"
                            value={
                                <TextInput
                                    value={interviewTime}
                                    onChange={setInterviewTime}
                                />
                            }
                        />
                        <InfoItem
                            valueWidth={157}
                            title="Результат собеседования"
                            value={
                                <Select
                                    options={interviewResultOptions}
                                    value={interviewResult}
                                    onChange={setInterviewResult}
                                    isClearable
                                />
                            }
                        />
                    </InfoLine>
                    <InfoLine>
                        <InfoItem
                            title="В каком служении находится"
                            value={
                                <TextInput
                                    value={ministryInfo}
                                    onChange={setMinistryInfo}
                                />
                            }
                        />
                        <SingleSelectEntity
                            label="Ответственный лидер"
                            maxWidth={157}
                            value={personalLeader}
                            onChange={setPersonalLeader}
                            loadOptions={loadMembersForSelect}
                        />
                        <SingleSelectEntity
                            label="Кто представляет церкви"
                            maxWidth={157}
                            value={presentForChurch}
                            onChange={setPresentForChurch}
                            loadOptions={loadMembersForSelect}
                        />
                        <MultipleSelectEntity
                            label="Собеседующие пастора"
                            maxWidth={157}
                            values={interviewPastors}
                            onChange={setInterviewPastors}
                            loadOptions={loadMembersForSelect}
                        />
                    </InfoLine>
                </TableContainer>
            )}
        </section>
    );
}

export default AdoptedInfoCardEdit;
